// src/pages/About.js
import React from 'react';

function About() {
  return (
    <div className='container'>
      <h1 className='borderBlue'>Page Assets</h1>
      <p className='borderGreen'>This is the About page.</p>


      <div className='container'>

      </div>


    </div>
  );
}

export default About;
