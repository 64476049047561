// src/pages/Home.js
import React from "react";

function Home() {
  return (
    <div>
      <h1>Home Page</h1>
      <p>Welcome to the Home page!</p>

      <div className="prim_color1">
        <h1>Welcome to the Home page!</h1>
      </div>


      <div className="prim_color2">
        <h2>Welcome to the Home page!</h2>
      </div>

      <div className="prim_color3">
        <h3>Welcome to the Home page!</h3>
      </div>

      <div className="prim_color4">
        <h4>Welcome to the Home page!</h4>
      </div>

      <div className="prim_color5">
        <h5>Welcome to the Home page!</h5>
      </div>

      <div className="prim_color6">
        <h6>Welcome to the Home page!</h6>
      </div>

    </div>
  );
}

export default Home;
