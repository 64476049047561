import React from "react";
import { Link } from "react-router-dom";
import "../styles/styles.css";
import SearchBar from "./SearchBar";
import Avatar from "./Avatar"; // Import the Avatar component

function NavBar() {
  const handleSearchChange = (event) => {
    // Add functionality for handling search input change
    console.log("Search query:", event.target.value);
  };

  return (
    <nav className="navBar">
      <div className="logo">Logo</div>
      <ul className="navLinks">
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/assets">Assets</Link>
        </li>
        {/* Add more links as needed */}
      </ul>
      <div className="navExtras">
        <SearchBar placeholder="Search" onChange={handleSearchChange} />
        <Avatar
          imageUrl="https://img.daisyui.com/images/stock/photo-1534528741775-53994a69daeb.jpg"
          altText="Avatar"
        />
      </div>
    </nav>
  );
}

export default NavBar;
