// src/components/SearchBar.js
import React from "react";

const SearchBar = ({ placeholder, onChange }) => {
  return (
    <input
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      className="searchBar"
    />
  );
};

export default SearchBar;
