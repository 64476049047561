// src/components/Avatar.js
import React from 'react';

const Avatar = ({ imageUrl, altText }) => {
  return (
    <div className="avatar">
      <div className="w-8 rounded-full">
        <img src={imageUrl} alt={altText} />
      </div>
    </div>
  );
};

export default Avatar;
